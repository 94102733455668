import React, {useState} from 'react';
import { Redirect } from 'react-router-dom';
import logo from "../../assets/images/ffqlogo.png";
import './style.scss';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const LoginView = ({ handleLogin, user }) => {
    const [mail, setMail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState('');
    const [open, setOpen] = useState(true);

    if (user) {
        return <Redirect to="/devices" />
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async () => {
        if (mail && password) {
            let status = await handleLogin({mail, password})
            setMail('')
            setPassword('')
            if (status && status.error) {setErrors(status.error)}
        }
    }

    return (
        <main className="main-login">
            <section className="login-container">
                <div className="logo">
                    <img src={logo} alt="" className="img-logo"/>
                </div>
                <div className="error-text">{errors}</div>
                <div>
                    Mail:
                    <input type='mail'
                           value={mail}
                           onChange={e => setMail(e.target.value)}
                    />
                </div>
                <div>
                    Password:
                    <input type='password'
                           value={password}
                           onChange={e => setPassword(e.target.value)}
                    />
                </div>
                <div className="btn-container">
                    <button className="main-btn" onClick={handleSubmit}>login</button>
                </div>
            </section>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Mensaje importante</DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
<p>Para ampliar la utilidad de la aplicación, se mejoró la función de Alerta de Desconexión. Configure los mensajes de alarmas de desconexión que desee siguiendo los siguientes pasos:<br/>
- Ingrese a la pestaña de “Alertas y Configuraciones”.<br/>
- Presión el botón “Nuevo” y seleccione “Disconnect” en el campo “Alarm Type”.<br/>
- Complete el nombre de la alerta, agregue una dirección de email, seleccione el tiempo de revisión de conexión y el canal de medición que requiere supervisar de su monitor.<br/>
- Presione “Crear” y comience a usar la función de mensajes de Alerta de Desconexión.<br/>
- Si la Alerta se activa, recibirá un mail notificando la desconexión. Cuando la conexión se restaure recibirá otro mail informando que hay conexión nuevamente.<br/>
</p>
<p>Recuerde que puede contactar a nuestros técnicos en el email ht@horn-technologies.cl si requiere soporte en el uso de la aplicación y de todos nuestros servicios. Esperamos continuar brindándoles nuestra mejor atención.</p>

<p>&nbsp;</p>

<p>In order to improve the user experience and usability of the application, the Disconnection Alarm feature was enhanced. Configure the desired disconnection alarm messages as follows:<br/>
- Enter the “Alerts and Config” tab.<br/>
- Press the “New” button and select “Disconnect” in the “Alarm Type” field.<br/>
- Fill in the alert name, add an email address, select the connection check time and the measurement channel you require to monitor from your monitor.<br/>
- Press “Create” and start using the Disconnect Alert messaging feature.<br/>
- If the Alert is activated, you will receive an email notifying you of the disconnection. When the connection is restored, you will receive another email informing you that there is a connection.<br/>
</p>
<p>Remember that you can contact our technicians at ht@horn-technologies.cl if you require support in the use of the application and our services. We look forward to continuing to provide you with our best service.</p>
	            </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>Cerrar</Button>
                </DialogActions>
            </Dialog>
        </main>
    )
}

export default LoginView;
